import React from 'react'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'

import footerLogo from '../../assets/images/officeworks-logo-footer.svg'

export const Footer = props => {
  const { expanded } = props

  return (
    <footer className={`footer ${expanded ? 'expanded' : ''}`}>
      {expanded && (
        <div className="container">
          <div className="row">
            <div className="col-sm-6 col-md-4">
              <Link className="logo" to="/">
                <img src={footerLogo} alt="accord with officeworks icon" />
              </Link>

              <ul>
                <li>
                  Officeworks Ltd T/A Accord with Officeworks ABN 36 004 763 526
                </li>
                <li>ACN XXX XXX XXX</li>
              </ul>
            </div>

            <div className="col-sm-6 col-md-4">
              <div className="footer__wrap footer__wrap_split footer__wrap_split_menu_column">
                <h4>General</h4>

                <ul id="menu-footer-column-two">
                  <li>
                    <Link to="about-the-nbn">About the nbn™</Link>
                  </li>
                  <li>
                    <Link to="broadband-usage-guide">
                      Broadband Usage Guide
                    </Link>
                  </li>
                  <li>
                    <Link to="switching-to-officeworks">
                      Switching to Officeworks
                    </Link>
                  </li>
                  <li>
                    <Link to="hardware">Hardware</Link>
                  </li>
                  <li>
                    <Link to="speed-information-for-nbn">
                      Speed Information for nbn™
                    </Link>
                  </li>
                  <li>
                    <Link to="faq">FAQ</Link>
                  </li>
                  <li>
                    <Link to="contact">Contact</Link>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-sm-6 col-md-4">
              <div className="footer__wrap footer__wrap_split footer__wrap_split_menu_column">
                <h4>Legal</h4>

                <ul id="menu-footer-column-two">
                  <li className="menu-item menu-what-you-need-to-know">
                    <Link to="what-you-need-to-know">
                      What you need to know
                    </Link>
                  </li>
                  <li>
                    <Link to="privacy-policy">Officeworks™ Privacy Policy</Link>
                  </li>
                  <li>
                    <Link to="hardware-warranty-policy">
                      Hardware &amp; Warranty Policy
                    </Link>
                  </li>
                  <li>
                    <a rel="noopener noreferrer" href="/payment-assistance">
                      Payment Assistance
                    </a>
                  </li>
                  <li>
                    <Link to="standard-form-of-agreement">SFOA</Link>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="/pdf/officeworks-Complaints-Handling-Process.pdf"
                    >
                      Complaints
                    </a>
                  </li>
                  <li>
                    <Link to="speed-information-for-nbn">
                      Speed Information for nbn™
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      )}
      {!expanded && (
        <div className="container">
          <nav role="navigation">
            <ul>
              <li>
                <Link to="/legal">Legal</Link>
              </li>
              <li>
                <Link to="/online-safety">Online Safety</Link>
              </li>
              <li>
                <a href="/pdf/complaint-handling-policy.pdf" target="_blank">
                  Complaints
                </a>
              </li>
              <li>
                <a href="/payment-assistance">Payment Assistance</a>
              </li>
              <li>
                <Link to="/faq">FAQs</Link>
              </li>
            </ul>
          </nav>
          <p className="copyright">
            Officeworks Ltd T/A Accord with Officeworks ABN 36 004 763 526
          </p>
        </div>
      )}
    </footer>
  )
}

Footer.defaultProps = {
  expanded: false,
}

Footer.propTypes = {
  expanded: PropTypes.bool,
}
